import React, { useState } from "react"
import styled, { css, keyframes } from "styled-components"
import Device from "../device"
import axios from "axios"

import Container from "./Container"
import ButtonStyle from "./ButtonStyle"

import { versionEmail } from "../versionTextHelper"

const Wrap = styled.section`
  width: 100%;
  background: #fff;
  padding: 100px 0 60px;
  position: relative;
  z-index: 3;

  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
    margin: 0 0 5px 0;

    @media ${Device.tablet} {
      margin: 0 0 15px 0;
    }
  }

  @media ${Device.tablet} {
    padding: 120px 0;
    min-height: 100vh;
  }
  @media ${Device.laptopM} {
    padding: 240px 0;
  }
`
const Title = styled.h2`
  width: 100%;
`
const ContactsList = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 30px 0;
  flex-wrap: wrap;

  @media ${Device.tablet} {
    margin: 0 0 60px 0;
  }
`
const ContactsListItem = styled.div`
  width: 100%;
  margin: 0 0 30px 0;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.color.primaryColor};
    font-weight: bold;
  }

  @media ${Device.tablet} {
    max-width: 360px;
    margin: 0;
  }
`
const LabelTop = css`
  font-size: 12px;
  transform: translateY(-15px);
  background: rgba(255, 255, 255, 0);
  opacity: 0.4;
  padding: 5px 0;
`
const FormTitle = styled.h3``
const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  @media ${Device.tablet} {
    margin-right: 390px;
  }
`
const FieldSet = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 20px 0;

  @media ${Device.tablet} {
    max-width: 360px;
    margin: 0 0 10px 0;
  }

  &.fullwidth {
    @media ${Device.tablet} {
      max-width: 100%;
    }
  }

  &.filled {
    label {
      ${LabelTop}
    }
  }
`

const Input = styled.input`
  &:focus {
    & + label {
      ${LabelTop}
    }
  }
`
const TextArea = styled.textarea`
  &:focus {
    & + label {
      ${LabelTop}
    }
  }
`
const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  transition: all 0.2s;
  cursor: pointer;

  @media ${Device.tablet} {
    padding: 10px 0;
    font-size: 16px;
  }
`
const Submit = styled.button`
  ${ButtonStyle}
  width: 100%;
  margin-top: 15px;

  @media ${Device.tablet} {
    width: auto;
    margin-top: 45px;
  }
`
const Rotate = keyframes`
0% {
  transform: rotate(0);
  opacity: 1;
}
50% {opacity: .5;}
100% {
  transform: rotate(360deg);
  opacity: 1;
}
`
const Loading = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  cursor: wait;

  &:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0);
    border-top: 4px solid ${(props) => props.theme.color.primaryColor};
    border-radius: 100px;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    animation-name: ${Rotate};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
`
const Status = styled.div`
  font-size: 18px;
  font-weight: normal;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin: 20px 0 0 0;
  border-radius: 4px;
  color: #fff;
  background-color: ${(props) => props.theme.color.secondaryColor};

  &.succes {
    background-color: green;
  }

  @media ${Device.tablet} {
    font-size: 20px;
    padding: 10px 20px;
  }
`

const Contacts = () => {
  const initFields = {
    first_name: "",
    email: "",
    comment: "",
  }
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)
  const [status, setStatus] = useState(false)
  const [fields, setFields] = useState(initFields)

  function updFields(e) {
    setFields({
      ...fields,
      [e.target.id]: e.target.value,
    })
  }
  function validateForm() {
    return (
      fields.first_name.length > 0 &&
      fields.email.length > 0 &&
      fields.comment.length > 0
    )
  }
  function serialize(data) {
    let flds = ""
    for (var item of data.entries()) {
      flds +=
        encodeURIComponent(item[0]) + "=" + encodeURIComponent(item[1]) + "&"
    }
    return flds
  }
  function requestErrorResult(error) {
    console.log(error.status)

    setIsLoading(false)
    setStatus("error")

    if (error.status === 403) {
      return setStatusMessage("Too much submit attempts! Try again later..")
    } else {
      setStatusMessage("Something wrong, please try again")
    }
  }
  function handleSubmit(e) {
    e.preventDefault()
    const data = new FormData(e.target)
    setIsLoading(true)

    axios
      .request({
        method: "post",
        url: "/clientsarea/contact_us_form/",
        data: serialize(data),
        withCredentials: true,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
      .then((response) => {
        setIsLoading(false)
        setStatus("succes")
        setStatusMessage("Successfully")
        setFields(initFields)
        console.log(response)
      })
      .catch((error) => {
        requestErrorResult(error.response)
      })
  }

  return (
    <Wrap id="contacts">
      <Container>
        <Title>Contacts</Title>
        <ContactsList>
          <ContactsListItem>
            <h3>General Query</h3>
            <a href={`mailto:info@${versionEmail()}`}>info@{versionEmail()}</a>
          </ContactsListItem>
          <ContactsListItem>
            <h3>Clients Support</h3>
            <a href={`mailto:support@${versionEmail()}`}>
              support@{versionEmail()}
            </a>
          </ContactsListItem>
          <ContactsListItem>
            <h3>Complaints</h3>
            <a href={`mailto:complaints@${versionEmail()}`}>
              complaints@{versionEmail()}
            </a>
          </ContactsListItem>
        </ContactsList>
        <FormTitle>Get in Touch with Us</FormTitle>
        <Form onSubmit={(e) => handleSubmit(e)} method="POST">
          <FieldSet className={fields.first_name.length > 0 ? "filled" : null}>
            <Input
              type="text"
              id="first_name"
              name="first_name"
              required
              onChange={(e) => updFields(e)}
              value={fields.first_name}
            />
            <Label htmlFor="first_name">Your Name</Label>
          </FieldSet>
          <FieldSet className={fields.email.length > 0 ? "filled" : null}>
            <Input
              type="email"
              id="email"
              name="email"
              required
              onChange={(e) => updFields(e)}
              value={fields.email}
            />
            <Label htmlFor="email">Email</Label>
          </FieldSet>
          <FieldSet
            className={
              fields.comment.length > 0 ? "fullwidth filled" : "fullwidth"
            }
          >
            <TextArea
              id="comment"
              name="comment"
              required
              rows="1"
              onChange={(e) => updFields(e)}
              defaultValue={fields.comment}
            ></TextArea>
            <Label htmlFor="comment">Your message</Label>
          </FieldSet>
          {statusMessage ? (
            <Status className={status}>{statusMessage}</Status>
          ) : null}
          <Submit disabled={!validateForm()}>Send</Submit>
          {isLoading ? <Loading /> : null}
        </Form>
      </Container>
    </Wrap>
  )
}

export default Contacts
