import React from "react"
import styled from "styled-components"
import Device from "../device"

import Container from "./Container"

import { ReactComponent as SvgLogo } from "../assets/logo.svg"
import { ReactComponent as SvgLogoEuxp } from "../assets/logo-euxp.svg"

import { version } from "../versionTextHelper"

const FooterWrap = styled.footer`
  width: 100%;
  background: #000;
  color: #fff;
  padding: 40px 0;

  @media ${Device.tablet} {
    padding: 100px 0;
  }
`
const LogoWrap = styled.div`
  width: 33.33%;
  margin: 0 0 20px 0;
`
const IconLogo = styled(
  process.env.REACT_APP_SITE_VERSION === "euxp" ? SvgLogoEuxp : SvgLogo
)`
  width: 103px;
  height: 32px;
  margin: 0;

  .svg-logo-1 {
    fill: #fff;
  }
`
const Copy = styled.div`
  width: 100%;
  opacity: 0.4;

  p {
    font-size: 12px;
    line-height: 15px;
  }
`
const Links = styled.div`
  width: 100%;
  opacity: 0.4;
  margin: 0 0 20px 0;

  a {
    color: #fff;
  }
`

const Footer = () => {
  return (
    <FooterWrap>
      <Container>
        <LogoWrap>
          <IconLogo />
        </LogoWrap>
        <Links>
          <a href="/documents">Documents</a>
        </Links>
        <Copy>
          <p>
            The&nbsp;{version()}
            &nbsp;Prepaid Mastercard® card is issued by Monavate Ltd pursuant to
            a license by Mastercard International Incorporated. The e-money
            associated with your card is issued by&nbsp;{version()}
            &nbsp;LTD.&nbsp;{version()}
            &nbsp;LTD authorised by the Financial Conduct Authority under the
            Electronic Money Regulations 2011 (register reference 900941) for
            the issuing of electronic money. Mastercard® is a registered
            trademark, and the circles design is a trademark of Mastercard
            International Incorporated.
          </p>
          <p>
            &copy; {new Date().getFullYear()}&nbsp;{version()}
            &nbsp;LTD. All rights reserved.
          </p>
        </Copy>
      </Container>
    </FooterWrap>
  )
}

export default Footer
