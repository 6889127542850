import React, { Fragment } from "react"
import styled from "styled-components"

import Topline from "../../components/Topline"
import Fees from "../../components/Fees/Fees"
import Footer from "../../components/Footer"

const Main = styled.main`
  width: 100%;
`

const FeesTpl = () => {
  return (
    <Fragment>
      <Topline />
      <Main>
        <Fees />
        <Footer />
      </Main>
    </Fragment>
  )
}
export default FeesTpl
