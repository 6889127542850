import React, { useState, useEffect } from "react"
import {
  createHistory,
  LocationProvider,
  Router,
  Redirect,
} from "@reach/router"
import { ThemeProvider } from "styled-components"
import axios from "axios"
import theme from "./theme"

import GlobalStyles from "./globalStyles"
import Homepage from "./screens/Homepage"
import FeesTpl from "./screens/FeesTpl"
import Documents from "./screens/Documents"
import LimitsPage from "./screens/Limits"
import Cards from "./screens/Cards"
import NotFound from "./screens/NotFound"

import AppContext from "./contexts/AppContext"

import TagManager from "react-gtm-module"

const routerHistory = createHistory(window)

const tagManagerArgs = {
  gtmId: "GTM-MFL78BL",
}
TagManager.initialize(tagManagerArgs)

function App() {
  const clientsareaInitial = {
    isAuthenticated: false,
    email: null,
    name: null,
  }
  const [modal, setModal] = useState(null)
  const [user, setUser] = useState(clientsareaInitial)
  const [country, setCountry] = useState("")

  function init() {
    const userData = window.CLIENTSAREA_USER_INFO
    if (userData) {
      setUser((user) => userData)
    }
  }

  if (window.location.href.indexOf("cards") > 0) {
    window.location.href = "/clientsarea/account/cards/"
  }

  useEffect(() => {
    window.scrollBy(0, 1)

    // check user isAuthenticated
    if (
      document.readyState !== "complete" &&
      document.readyState !== "interactive"
    ) {
      document.addEventListener("DOMContentLoaded", init)
    } else {
      init()
    }

    // Get country code
    axios
      .get("/clientsarea/locate_me/", {})
      .then(function (response) {
        if (response) {
          setCountry((country) => {
            if (response.data.country_code) {
              return String(response.data.country_code.toLowerCase())
            }
            return ""
          })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const appState = {
    user,
    country,
    setModal,
  }

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={appState}>
        <GlobalStyles />
        <LocationProvider history={routerHistory}>
          <Router className="routerwrap">
            <Homepage path="/" />
            <FeesTpl path="/fees/" />
            <Redirect from="/fees-new/" to="/fees/" />
            <Documents path="/documents/" />
            <LimitsPage path="/cardlimits/" />
            <Cards path="/cards/" />
            <NotFound default />
          </Router>
        </LocationProvider>
        {modal}
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default App
