import React from "react"
import styled, { keyframes } from "styled-components"
import Device from "../device"

import { version } from "../versionTextHelper"

import Container from "./Container"

const Wrap = styled.section`
  width: 100%;
  background: #fff;
  padding: 80px 0;
  position: relative;
  z-index: 4;

  @media ${Device.tablet} {
    padding: 120px 0 40px;
  }
  @media ${Device.laptopM} {
    padding: 120px 0 40px;
  }
`
const Content = styled.section`
  flex: 1;
`
const Title = styled.h2`
  width: 100%;
  margin: 0 0 30px 0;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  margin: 32px 0 0 0;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  @media ${Device.tablet} {
    margin: 64px 0 0 0;
    justify-content: flex-start;
  }
`
const Col = styled.div`
  width: 100%;
  max-width: 45%;
  margin: 0 0 15px 0;

  @media ${Device.tablet} {
    max-width: 130px;
    margin: 0 30px 0 0;
  }
`
const FeeName = styled.div`
  color: ${(props) => props.theme.color.secondaryColor};
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 15px 0;
  font-weight: 800;
  display: flex;
  align-items: flex-end;

  span {
    &:first-child {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const levi = keyframes`
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
`
const MoreWrap = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 45%;
  margin: 0 0 15px 0;

  @media ${Device.tablet} {
    max-width: 100%;
    margin: 30px 0 0 0;
  }
`
const More = styled.a`
  display: block;
  font-weight: bold;
  margin: 0;
  color: ${(props) => props.theme.color.primaryColor};
  text-decoration: none;
`
const Card = styled.div`
  display: none;

  @media ${Device.tablet} {
    display: block;
    width: 570px;
    text-align: center;
    transform: translateY(60px);
  }
`
const CardImg = styled.img`
  max-width: 100%;
  animation-name: ${levi};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  filter: drop-shadow(-10px 30px 10px rgba(0, 0, 0, 0.2));
`

const FeesSection = () => {
  return (
    <Wrap id="fees">
      <Container>
        <Title>Our Fees</Title>
        <Content>
          <p>
            Use&nbsp;{version()}
            &nbsp;account for your everyday financial guide:
          </p>
          <Row>
            <Col>
              <FeeName>Free</FeeName>
              <p>Top up</p>
            </Col>
            <Col>
              <FeeName>
                <span>from</span>&nbsp;<span>€0</span>
              </FeeName>
              <p>Monthly maintenance fee</p>
            </Col>
            <MoreWrap>
              <More href="/fees">Other Fees →</More>
            </MoreWrap>
          </Row>
        </Content>
        <Card>
          <CardImg
            src={
              process.env.REACT_APP_SITE_VERSION === "euxp"
                ? "/i/fees/euxp-card-top@1x.png"
                : "/i/fees/gbxp-card-top@1x.png"
            }
            srcSet={
              process.env.REACT_APP_SITE_VERSION === "euxp"
                ? "/i/fees/euxp-card-top@2x.png 2x"
                : "/i/fees/gbxp-card-top@2x.png 2x"
            }
            alt="Card"
          />
        </Card>
      </Container>
    </Wrap>
  )
}

export default FeesSection
