const Theme = {
  color: {
    primaryColor:
      process.env.REACT_APP_SITE_VERSION === "euxp" ? "#003399" : "#00237d",
    secondaryColor:
      process.env.REACT_APP_SITE_VERSION === "euxp" ? "#FFCD00" : "#dc1428",
  },
  width: {
    container: "1170px",
  },
}

export default Theme
