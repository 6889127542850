import React, { useContext } from "react"
import styled, { css } from "styled-components"
import Device from "../device"

import AppContext from "../contexts/AppContext"

import { ReactComponent as SvgGlobe } from "../assets/icon-feather-globe.svg"
import { ReactComponent as SvgCurrencies } from "../assets/icon-currencies.svg"
import { ReactComponent as SvgVault } from "../assets/icon-vault.svg"

import Container from "./Container"
import ButtonStyle from "./ButtonStyle"
import FormModal from "./FormModal"

const PersonalWrap = styled.section`
  width: 100%;
  padding: 120px 0 60px 0;
  background: #fff;
  position: relative;
  z-index: 2;

  @media ${Device.tablet} {
    padding: 240px 0;
  }
`
const Left = styled.div`
  @media ${Device.tablet} {
    max-width: 350px;
  }
`
const Right = styled.div`
  @media ${Device.tablet} {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`
const Title = styled.h2`
  margin: 0 0 32px 0;
`
const IntroText = styled.p`
  margin: 0 0 50px 0;

  @media ${Device.tablet} {
    margin: 0;
  }
`
const Button = styled.button`
  ${ButtonStyle}
  margin-top: 30px;
  display: none;

  @media ${Device.tablet} {
    display: inline-block;
  }

  &.mobile-only {
    display: block;
    @media ${Device.tablet} {
      display: none;
    }
  }
`
const ToAccount = styled.a`
  ${ButtonStyle}
  margin-top: 30px;
  display: none;

  @media ${Device.tablet} {
    display: inline-block;
  }

  &.mobile-only {
    display: block;
    @media ${Device.tablet} {
      display: none;
    }
  }
`
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const ListItem = styled.li`
  width: 100%;
  position: relative;
  margin: 0 0 40px 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${Device.tablet} {
    max-width: 670px;
    margin: 0 0 20px 0;
  }
`
const ListImg = styled.div`
  color: ${(props) => props.theme.color.secondaryColor};
  width: 100%;
  margin: 0 0 15px 0;
  max-height: 40px;
  @media ${Device.tablet} {
    max-width: 100px;
    text-align: right;
    margin: 0;
  }
  svg {
    max-height: 100%;
  }
`
const ListTitle = styled.h3`
  margin: 0 0 10px 0;
  line-height: 30px;
`
const ListText = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    flex: 1;
    padding-left: 30px;
  }
`

const IconCss = css`
  fill: ${(props) => props.theme.color.secondaryColor};
  stroke: ${(props) => props.theme.color.secondaryColor};
  height: 33px;
`
const IconGlobe = styled(SvgGlobe)`
  ${IconCss}
`
const IconCurrencies = styled(SvgCurrencies)`
  ${IconCss}
`
const IconVault = styled(SvgVault)`
  ${IconCss}
`

const Personal = () => {
  const { setModal, user } = useContext(AppContext)

  const Open = (props) => {
    return (
      <>
        {user.isAuthenticated && user.isAuthenticated !== "false" ? (
          <ToAccount href="/clientsarea/account/" className={props.className}>
            My account
          </ToAccount>
        ) : (
          <Button
            onClick={() => setModal(<FormModal />)}
            className={props.className}
          >
            Open an account
          </Button>
        )}
      </>
    )
  }

  return (
    <PersonalWrap>
      <Container>
        <Left>
          <Title>
            Personal, <br />
            Business <br />
            Account
          </Title>
          <IntroText>
            Open an&nbsp;individual or&nbsp;corporate account
            in&nbsp;a&nbsp;snap!
          </IntroText>
          <Open />
        </Left>
        <Right>
          <List>
            <ListItem>
              <ListImg>
                <IconGlobe />
              </ListImg>
              <ListText>
                <ListTitle>Payments with no borders</ListTitle>
                <p>
                  Doesn’t matter where your employees and counterparties are
                  located. Pay invoices and send funds across the globe.
                </p>
              </ListText>
            </ListItem>
            <ListItem>
              <ListImg>
                <IconCurrencies />
              </ListImg>
              <ListText>
                <ListTitle>Choose your currency at will</ListTitle>
                <p>
                  Transfer funds in GBP, EUR. Stay tuned as we add more
                  currencies over time!
                </p>
              </ListText>
            </ListItem>
            <ListItem>
              <ListImg>
                <IconVault />
              </ListImg>
              <ListText>
                <ListTitle>Robust regulation</ListTitle>
                <p>
                  FCA Authorised Electronic Money Institution (Reference number:
                  900941)
                </p>
              </ListText>
            </ListItem>
          </List>
        </Right>
        <Open className="mobile-only" />
      </Container>
    </PersonalWrap>
  )
}

export default Personal
