import React, { useContext } from "react"
import styled, { css } from "styled-components"
import Device from "../device"

import AppContext from "../contexts/AppContext"

import Container from "./Container"
import ButtonStyle from "./ButtonStyle"
import FormModal from "./FormModal"

import { ReactComponent as SvgUser } from "../assets/about/icon-feather-user-check.svg"
import { ReactComponent as SvgShop } from "../assets/about/icon-feather-shopping-bag.svg"
import { ReactComponent as SvgWifi } from "../assets/prepaid/icon-feather-wifi.svg"

import { version } from "../versionTextHelper"

const Wrap = styled.section`
  width: 100%;
  background: #f2f3f8;
  padding: 100px 0 60px;
  position: relative;
  z-index: 3;

  @media ${Device.tablet} {
    padding: 120px 0;
    min-height: 100vh;
  }
  @media ${Device.laptopM} {
    padding: 240px 0;
  }
`
const Title = styled.h2``
const Features = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
`
const FeatureItem = styled.div`
  width: 100%;
  margin: 0 0 45px 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${Device.tablet} {
    max-width: 33.33%;
    margin: 0;
    padding: 0 50px 0 0;
  }
`
const Footer = styled.footer`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 65px 0 0 0;
  flex-wrap: wrap;
`
const FooterTitle = styled.h3`
  margin: 0 0 32px 0;
  font-weight: 400;
  font-size: 24px;
`
const FooterLeft = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    max-width: 66.66%;
    padding: 0 100px 0 0;
  }
`
const FooterRight = styled.div`
  width: 100%;
  margin-top: 40px;

  @media ${Device.tablet} {
    max-width: 33.33%;
    padding: 0 50px 0 0;
    margin-top: 0;
  }
`
const Slogan = styled.div`
  font-size: 24px;
  line-height: 30px;
  color: ${(props) => props.theme.color.primaryColor};

  span {
    font-weight: 800;
    display: block;
  }
`
const Open = styled.button`
  ${ButtonStyle}
  margin: 50px 0 0 0;
`
const ToAccount = styled.a`
  ${ButtonStyle}
  margin: 50px 0 0 0;
`

const IconCss = css`
  height: 30px;
  stroke: ${(props) => props.theme.color.secondaryColor};
  margin: 0 20px 15px 0;
`
const IconUser = styled(SvgUser)`
  ${IconCss}
`
const IconShop = styled(SvgShop)`
  ${IconCss}
`
const IconWifi = styled(SvgWifi)`
  ${IconCss}
`

const About = () => {
  const { setModal, user } = useContext(AppContext)
  return (
    <Wrap id="about">
      <Container>
        <Title>About Us</Title>
        <Features>
          <FeatureItem>
            <IconUser />
            <p>
              Check and top up your card balance through the &nbsp;{version()}
              &nbsp;client area
            </p>
          </FeatureItem>
          <FeatureItem>
            <IconShop />
            <p>Make seamless purchases with contactless&nbsp;card payments</p>
          </FeatureItem>
          <FeatureItem>
            <IconWifi />
            <p>Enjoy Contactless payments</p>
          </FeatureItem>
        </Features>
        <Footer>
          <FooterLeft>
            <FooterTitle>
              Electronic Money Institution&nbsp;{version()}
              &nbsp;Limited.
            </FooterTitle>
            <p>
              What is it all about? Receiving payments from other countries has
              always been challenging. London-based and FCA-licensed&nbsp;
              {version()}
              &nbsp;tackles the issue by providing a new transparent solution
              for the clients to receive, transfer and spend funds.
            </p>
            <p>Premium Products are coming soon.</p>
          </FooterLeft>
          <FooterRight>
            <Slogan>
              It’s not a bank, it’s something better! <br />
              <br />
              <span>Manage your finance with&nbsp;{version()}.</span>
            </Slogan>

            {user.isAuthenticated && user.isAuthenticated !== "false" ? (
              <ToAccount href="/clientsarea/account/">My account</ToAccount>
            ) : (
              <Open onClick={() => setModal(<FormModal />)}>
                Open an account
              </Open>
            )}
          </FooterRight>
        </Footer>
      </Container>
    </Wrap>
  )
}

export default About
