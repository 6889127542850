import React, { useContext } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import Device from "../device"

import AppContext from "../contexts/AppContext"

const node = document.getElementById("modal")

const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;

  z-index: 999;

  @media ${Device.laptop} {
    padding: 30px;
  }
`

const MainModalWindow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 0;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  margin: 0 auto;
  z-index: 3;

  &.min {
    @media ${Device.laptop} {
      max-width: 640px;
    }
  }

  @media ${Device.laptop} {
    max-width: 770px;
  }
`
const ModalBackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  cursor: pointer;

  @supports (
    (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))
  ) {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
`

export function Modal({ bgColor, padding, textAlign, modalWidth, children }) {
  const { setModal } = useContext(AppContext)

  function handleCloseModal() {
    setModal(null)
  }

  return createPortal(
    <ModalWrap>
      <MainModalWindow
        bgColor={bgColor}
        padding={padding}
        textAlign={textAlign}
        className={modalWidth}
      >
        {children}
      </MainModalWindow>
      <ModalBackDrop onClick={handleCloseModal} />
    </ModalWrap>,
    node
  )
}
