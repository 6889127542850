import { css } from "styled-components"
import Device from "../device"

const ButtonStyle = css`
  display: inline-block;
  background: ${(props) => props.theme.color.secondaryColor};
  text-decoration: none;
  color: ${process.env.REACT_APP_SITE_VERSION === "euxp"
    ? (props) => props.theme.color.primaryColor
    : "#fff"};
  border-radius: 100px;
  padding: 10px 22px;
  border: none;
  margin: 0;
  line-height: 20px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  width: 100%;

  @media ${Device.tablet} {
    min-width: 170px;
    width: auto;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export default ButtonStyle
