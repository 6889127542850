import { createGlobalStyle } from "styled-components"
import Device from "./device"

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    vertical-align: top;
  }
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  body {
    display: flex;
    background: #fff;
    color: #222;
    font-family: 'Muli', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    display: flex;
    width: 100%;
  }
  #root,
  .routerwrap {
    min-height: 100%;
    flex: 1;
  }
  .routerwrap {
    flex: 1;
  }
  h2 {
    font-size: 36px;
    line-height: 45px;
    color: ${(props) => props.theme.color.primaryColor};
    font-weight: 400;
    margin-top: 0;

    span {
      font-weight: 800;
    }

    @media ${Device.tablet} {
      font-size: 56px;
      line-height: 70px;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px 0;
  }
  button {
    appearance: none;
  }
  textarea, input {
    border: none !important;
    border-bottom: 1px solid rgba(0,0,0,.4) !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left:  0;
    padding-right: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px !important;
    color: #000;
    width: 100%;
    appearance: none;
    border-radius: 0 !important;

    @media ${Device.tablet} {
      font-size: 16px !important;
    }

    &:focus {
      outline: 0;
      border-color: ${(props) => props.theme.color.primaryColor} !important;
    }
  }
  .svg-logo-1 {
    fill: ${(props) => props.theme.color.primaryColor};
  }
  .svg-logo-2 {
    fill: ${(props) => props.theme.color.secondaryColor};
  }
  .react-tel-input .form-control {
    width: 100% !important;
    height: auto !important;
  }
  .react-tel-input .country-list {
    width: 250px !important;

    @media ${Device.tablet} {
      width: 300px !important;
    }
  }
  .react-tel-input .country-list .search {
    display: flex;
  }
  .react-tel-input .country-list .search-box {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .openedMenu {
    height: 100vh;
    overflow: hidden;
  }
  main {
    @media ${Device.tablet} {
      overflow: hidden;
    }
  }
`

export default GlobalStyles
